import React from "react"
import ReactDOM from "react-dom";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import ImgSlogan from "../img/A_glowna_1/instanta_logo_2.png";
import ImgKawa from "../img/A_glowna_1/iko_kawa.png";
import ImgOpak from "../img/A_glowna_1/iko_opakowania.png";
import ImgProd from "../img/A_glowna_1/iko_produkt.png";
import ImgZakl from "../img/A_glowna_1/iko_zaklady.png";
//require('./less.less')






import 'isomorphic-fetch'; //index file create
// fonts

import "fontsource-roboto/latin-ext-300.css"
import "fontsource-roboto/latin-ext-400.css"
import "fontsource-roboto/latin-ext-700.css"

// end fonts

import { Router } from "@reach/router"


// Import layouts here
import Suwak from "../layouts/Suwak"

import Menu from "../layouts/menu";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
// Import styles here
import '../styles/global.css';
// plugins
import { Helmet }   from "react-helmet";
import i18next from '../i18n/config';
//function chlang(lang) {
//    console.log(lang, "chlang");
//}


require('typeface-roboto');
// import favicon from '../img/favi_icon_16px.png';
//      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />

// const $ = require("jquery")



export default  class Home extends React.Component {

    constructor(props) {
        super(props);
        if (typeof window !== `undefined`) {
            document.lastclientX = 0;
            document.lastclientY = 0;
            const root = document.documentElement;
//            let x = window.event / window.innerWidth;
//            let y = evt.clientY / window.innerHeight;
//            root.style.setProperty('--mouse-x', x);
//            root.style.setProperty('--mouse-y', y);
            document.addEventListener('mousemove', evt => {
                let x = evt.clientX / window.innerWidth;
                let y = evt.clientY / window.innerHeight;
//            console.log(document.lastclientX - x);
                if (
                        Math.abs(document.lastclientX) - evt.clientX > 222
                        ||
                        Math.abs(document.lastclientY - evt.clientY) > 222

                        ) {
//                console.log("set transition");
                    try {
                        var div = document.getElementById("header222");
                        div.style.transition = 'all 0.3s ease';
                    } catch (error) {
                    }
                    setTimeout(function () {
                        try {
                            var div = document.getElementById("header222");
                            div.style.transition = 'none';
                        } catch (error) {
                        }
                    }, 402);
                } else {



                }




                root.style.setProperty('--mouse-x', x / 2);
                root.style.setProperty('--mouse-y', y / 2);
                document.lastclientX = evt.clientX;
                document.lastclientY = evt.clientY;
            });
//////////////////////////////////////



            let constrain = 20;
            function transforms(x, y, el) {
                let box = el.getBoundingClientRect();
                let calcX = -(y - box.y - (box.height / 2)) / constrain;
                let calcY = (x - box.x - (box.width / 2)) / constrain;
                root.style.setProperty('--logo-rotate-x', (calcX * 0.1) + "deg");
                root.style.setProperty('--logo-rotate-y', (calcY / 6) + "deg");
//            return "perspective(700px) "
//                    + "   rotateX(" + calcX / 6 + "deg) "
//                    + "   rotateY(" + calcY / 6 + "deg) ";
            }
            ;
            function transformElement(el, xyEl) {
                transforms.apply(null, xyEl);
            }





            document.addEventListener('mousemove', e => {

                let x = e.clientX; // / (window.innerWidth / 3);
                let y = e.clientY; // / (window.innerHeight / 2);

                let calcX = -(y - (window.innerHeight / 2)) / 20;
                let calcY = (x - (window.innerWidth / 2)) / 20;
                root.style.setProperty('--logo-rotate-x', (calcX * 0.1) + "deg");
                root.style.setProperty('--logo-rotate-y', (calcY / 3) + "deg");
            });
//        let constrain = 20;
//
//        function transforms(x, y, el) {
//            let box = el.getBoundingClientRect();
//            let calcX = -(y - box.y - (box.height / 2)) / constrain;
//            let calcY = (x - box.x - (box.width / 2)) / constrain;
//            return "perspective(700px) "
//                    + "   rotateX(" + calcX / 6 + "deg) "
//                    + "   rotateY(" + calcY / 6 + "deg) ";
//        }
//        ;
//        function transformElement(el, xyEl) {
//            el.style.transform = transforms.apply(null, xyEl);
//        }
//
//
//        document.addEventListener('mousemove', e => {
//
////        mouseOverContainer.onmousemove = function (e) {
//            let xy = [e.clientX, e.clientY];
//            var ex1Layer = document.getElementById("imgslogan");
//
//            let position = xy.concat([ex1Layer]);
//            window.requestAnimationFrame(function () {
//                transformElement(ex1Layer, position);
//            });
//        });
        }

    }

    render() {

        i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
        });
//        console.log(this);
//    i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
//    });


//    if (typeof document !== `undefined`) {
//        locationTmp = window.location.pathname + window.location.search;
//        let first3 = locationTmp.substr(0, 3);
//        if (first3 == "/pl") {
//            i18next.changeLanguage('pl', (err, t) => {
//            });
//        }
//        if (first3 == "/es") {
//            i18next.changeLanguage('es', (err, t) => {
//            });
//        }
//    }



        return (
                <React.Fragment>

                    <div className="App" >
                        <Helmet>
                            <meta charSet="utf-8" />
                            <link rel="canonical" href="https://www.instanta.pl/" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="icon" type="image/png" href={require('../img/template/favicon.png')} sizes="16x16" />
                            <title>Instanta Coffee Brand</title>
                            <meta name="Description" content="The company specializes in the production of private label coffee" />
                            <meta name="Keywords" content="private label coffee" />
                        </Helmet>


















                        <div class="wrapper">
                            <Menu/>



                            <header id="">
                                <section id="header222" class="">

                                    <div class="slogan">
                                        <Link to="/AboutUs/">
                                        <img id="imgslogan" loading="lazy" src={ImgSlogan} alt="Instanta Logo" />
                                        </Link>
                                    </div>


                                </section>
                            </header>

                            <section id="header2" class="header2">

                                <div class="mainnavi">
                                    <div>
                                        <Link
                                            to="/OurCoffees/"
                                            >
                                        <img loading="lazy" src=
                                             {ImgKawa}
                                             alt="Instanta Coffee Icon" />
                                        <p>
                                            {i18next.t('MainNavi1')}
                                        </p>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/OurPackaging/Packaging/">
                                        <img loading="lazy" src=
                                             {ImgOpak}
                                             alt="Instanta Package Icon" />
                                        <p>
                                            {i18next.t('MainNavi2')}
                                        </p>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/Collaboration/">
                                        <img loading="lazy" src=



                                             {ImgProd
                                             }



                                             alt="Instanta Product Icon" />
                                        <p>



                                            {i18next.t('MainNavi3')
                                            }



                                        </p>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/AboutUs/OurPlants/">
                                        <img loading="lazy" src=



                                             {ImgZakl
                                             }



                                             alt="Instanta Production Icon" />
                                        <p>



                                            {i18next.t('MainNavi4')
                                            }



                                        </p>
                                        </Link>
                                    </div>









                                </div>

                            </section>
















                            <Suwak/>
                        </div>

                    </div>





                </React.Fragment>

                )
    }
}